module.exports = {
	localizationUnits: {
		ru: {
			"pc": {
				1: "шт",
			},
			"l": {
				0.001: "мл",
				1: "л",
				1000: "м3",
			},
			"kg": {
				0.001: "г",
				1: "кг",
				100: "ц",
				1000: "т",
			},
			"cr": {
				1: "уп",
			},
		},
		en: {
			"pc": {
				1: "pc",
			},
			"l": {
				1: "l",
			},
			"kg": {
				1: "kg",
			},
			"cr": {
				1: "pack",
			},
		},
	},
	regions: [
		{ currency: "RUB", lang: "ru", country: "RU", currencySymbol: "₽" },
		{ currency: "AMD", lang: "hy", country: "AM", currencySymbol: "֏" },
	],
};
