
define([
	"lodash",
	"tinybone/base",
	"tinybone/backadapter",
	"safe",
	"jquery",
	"geolocator",
	"utils/custom-dialog",
	"dustc!views/header/header.dust",
], function (_, tb, api, safe, $, geolocator, Dialog) {
	var view = tb.View;
	let gt;
	var View = view.extend({
		id: "views/header/header",
		postRender: function () {
			gt = this.get("_t_gettext");
			let self = this;

			// how to access app locals console.log(this.app.locals._t_regions);

			if (self.$el.is(":hidden"))
				return;

			var role = this.get("currentUser.role");
			if (_.isString(role) && role.length > 0 && role != "guest")
				api("deal.getNewDealsCount", self.app.getToken(), {}, function (err, data) {
					if (err)
						self.app.clientError(err);
					else {
						var msgTotal = 0;
						_.each(data.list, function (cur) {
							if (cur.msgCount > 0)
								msgTotal += cur.msgCount;
							else
								msgTotal += 0;
						});
						data.msgTotal = msgTotal;
						self.views.forEach(function (view) {
							if (view.name.includes("notify_count") && msgTotal > 0) {
								view.locals.notifyCount = msgTotal;
								view.refresh(self.app.errHandler);
							}
							if (view.name.includes("top_profile_menu") && msgTotal > 0) {
								view.locals.notifyCount = msgTotal;
								view.refresh(self.app.errHandler);
							}
						});
						if (self.parent != null && msgTotal > 0)
							self.parent.trigger("showNewDealsCount", data);
					}
					setTimeout(function () {
						self.refresh(self.app.errHandler);
					}, 60000);
				});


			window.onclick = function (event) {
				let $dealsDrop = $(".dropdown-content-deals");
				if ($(".dealsDrop").hasClass("click"))
					$(".dealsDrop").removeClass("click");
				else
					$dealsDrop.each(function (e) {
						if ($(this).css("display") == "block")
							$(this).toggle("hide");
					});

				if ($("#myDropdown").css("display") == "block")
					if ([
						"dropbtn",
						"sphere bg-green-1",
					].indexOf(event.target.parentElement.className) < 0)
						$("#myDropdown").toggle("show");
			};

			$(window).scrollTop(0);
			$(".top-button").fadeOut();
			let $scrollBlock = $(".scrolling-block");
			$scrollBlock.scroll(function () {
				if ($(this).scrollTop() > 180)
					$(".top-button").fadeIn();
				else
					$(".top-button").fadeOut();
			});

			self.$el.find("#name-filter-value-large-screen").keydown((event) => {
				if (event.keyCode == 13) {
					event.preventDefault();
					self.$el.find("#name-filter-button").click();
				}
			});

			let geoloc = $.cookie("geoloc");
			safe.run(async function () {
				if (geoloc) {
					geoloc = JSON.parse(geoloc);
					geoloc.displayed = true;
					if (Object.keys(gt.catalogs)[0] !== geoloc.lang) {
						if (geoloc.city === gt.gettext("Уточнено")) {
							geoloc.city = gt.gettext("Уточнено");
							geoloc.lang = Object.keys(gt.catalogs)[0];
							geoloc.displayed = false;
						}
						// if the translation is not in the cookie
						if (!geoloc[`city${Object.keys(gt.catalogs)[0]}`]) {
							const res = await api("coreapi.getTranslate", self.app.getToken(), {
								idorig: geoloc.city.toString(16),
								fieldName: "city",
								target: Object.keys(gt.catalogs)[0],
								source: geoloc.lang,
								origtext: geoloc.city,
							}, function () {});
							if (res) {
								geoloc[`city${Object.keys(gt.catalogs)[0]}`] = res;
								geoloc.city = res;
								geoloc.lang = Object.keys(gt.catalogs)[0];
								$.cookie("geoloc", JSON.stringify(geoloc), {
									expires: 3,
									path: "/",
								});
								geoloc.displayed = false;
							}
						}
						if (geoloc[`city${Object.keys(gt.catalogs)[0]}`]) {
							geoloc.city = geoloc[`city${Object.keys(gt.catalogs)[0]}`];
							geoloc.lang = Object.keys(gt.catalogs)[0];
							geoloc.displayed = false;
						}
					}
					// After we define the first city and coordinates it's not set up in the header. So we need to refresh the page.
					if (!geoloc.displayed) {
						geoloc.displayed = true;
						$.cookie("geoloc", JSON.stringify(geoloc), { expires: 3, path: "/" });
						self.app.router.reload();
					}
				}
			}, function (err) {
				self.app.clientError(err);
			});

			geolocator.config({
				language: "ru",
				google: { version: "3" },
			});

			geolocator.setGeoIPSource({
				provider: "geoiplocation",
				url: "/geoip/ipaddress",
				xhr: true,
				schema: {
					ip: "ip",
					coords: {
						latitude: "latitude",
						longitude: "longitude",
					},
					address: {
						city: "city",
						state: "region",
						stateCode: "",
						postalCode: "",
						countryCode: "country_code",
						country: "country",
						region: "region",
					},
					timezone: {
						id: "timezone",
					},
				},
			});
		},
		events: {
			// go to the roster
			"click .logout": function () {
				var self = this;
				api("users.logout", self.app.getToken(), {}, safe.sure(self.app.errHandler, function () {
					self.app.clearToken();
					api.invalidate();
					setTimeout(function () {
						window.location.reload();
					}, 0);
				}));
				return false;
			},
			"click .precise-position": function () {
				let self = this;

				let geoloc = $.cookie("geoloc");
				if (geoloc)
					geoloc = JSON.parse(geoloc);


				let dialog = new Dialog({
					title: gt.gettext("Внимание!"),
					text: gt.gettext("Для более качественного подбора товаров необходимо уточнить ваше местоположение. Вы уверены, что хотите разрешить браузеру определить ваше местоположение автоматически?"),
				});

				dialog.confirm(function () {
					if (!geolocator.isGeolocationSupported())
						return self.app.clientError(new Error(gt.gettext("Гео локация не включена или не поддерживается вашим устройством!")));


					safe.run(function (cb) {
						self.app.blockLayer();

						geolocator.locate({
							enableHighAccuracy: true,
							timeout: 5000,
							maximumWait: 10000,
							fallbackToIP: true,
							maximumAge: 0,
							desiredAccuracy: 100,
						}, safe.sure(cb, function (loc) {
							if (!loc)
								return cb({ code: "INVALID_RESPONSE" });

							if (!geoloc)
								geoloc = {};
							geoloc.coordinates = [
								loc.coords.longitude,
								loc.coords.latitude,
							];

							api("points.getCoordinatesAddress", self.app.getToken(), geoloc.coordinates, (err, res) => {
								if (!err && res)
									geoloc.city = res.city || res.state || res.country;

								if (!geoloc.city)
									geoloc.city = gt.gettext("Уточнено");

								geoloc.lang = res.lang;

								geoloc[`city${geoloc.lang}`] = geoloc.city;
								geoloc.precise = true;
								if (Object.keys(gt.catalogs)[0] !== geoloc.lang && !geoloc[`city${Object.keys(gt.catalogs)[0]}`])
									api("coreapi.getTranslate", self.app.getToken(), {
										idorig: geoloc.city.toString(16),
										fieldName: "city",
										target: Object.keys(gt.catalogs)[0],
										source: geoloc.lang,
										origtext: geoloc.city,
									}, (err, res) => {
										if (!err) {
											geoloc[`city${Object.keys(gt.catalogs)[0]}`] = res;
											geoloc.city = res;
											geoloc.lang = Object.keys(gt.catalogs)[0];
											$.cookie("geoloc", JSON.stringify(geoloc), {
												expires: 3,
												path: "/",
											});
										}
										self.app.unblockLayer();
										self.app.router.reload();
									});
								else {
									$.cookie("geoloc", JSON.stringify(geoloc), { expires: 3, path: "/" });
									self.app.unblockLayer();
									self.app.router.reload();
								}
							});
						}));
					}, function (err) {
						if (err) self.app.unblockLayer();
						if (err && err.code === "INVALID_RESPONSE")
							err.message = gt.gettext("Не удалось определить местоположение.");

						self.app.clientError(err);
					});
				});

				dialog.show();
			},
			"click #name-filter-button": function (e) {
				let self = this;

				e.preventDefault();

				safe.run(function (cb) {
					self.app.blockLayer();

					// Note: Search replaces all the other filters (paging, prices etc).
					let filter = self.$el.find("#name-filter-value-large-screen").val();
					if (filter)
						filter = filter.trim();


					let query = filter ? `?name=${filter}` : "";
					self.app.router.navigateTo(`${self.app.getPrefix()}/${query}`, cb);
				}, function (err) {
					self.app.unblockLayer();
					self.app.clientError(err);
				});
			},
			"click .dropbtn-small": function () {
				if ($("#myDropdown-small:first").is(":hidden"))
					$("#myDropdown-small").slideDown();
				else
					$("#myDropdown-small").slideUp();
			},
			"click .dropbtn": function (e) {
				e.preventDefault();
				e.stopPropagation();
				if ($("#myDropdown").css("display") != "block") {
					let user_name_width = parseInt($(".profile-menu-user-name").css("width"));
					let user_name_position = $(".profile-menu-user-name").position();
					let avatar_left = user_name_position.left + user_name_width + 28 - 180;
					$(".dropdown-content").css("left", (avatar_left) + "px");
				}
				$("#myDropdown").toggle("show");
			},
			"click #changeLanguage": function (e) {
				e.preventDefault();
				let self = this;
				var _id = this.get("currentUser._id");
				var language = $(e.currentTarget).attr("lang");
				safe.run(function (cb) {
					self.app.blockLayer();
					api("users.setLanguage", self.app.getToken(), {
						_id,
						language,
					}, function () {
						self.app.unblockLayer();
						window.location.reload();
					});
				}, function (err) {
					self.app.unblockLayer();
					self.app.clientError(err);
				});
			},
			"change #changeCurrency": function (e) {
				e.preventDefault();
				let self = this;
				var currency = $(e.currentTarget).val();
				var _id = this.get("currentUser._id");
				safe.run(function (cb) {
					self.app.blockLayer();
					api("users.setCurrency", self.app.getToken(), {
						_id,
						currency,
					}, function () {
						self.app.unblockLayer();
						self.app.router.reload();
					});
				}, function (err) {
					self.app.unblockLayer();
					self.app.clientError(err);
				});
			},
		},
	});
	View.id = "views/header/header";
	return View;
});

