define([
	"lodash",
	"tinybatch",
	"promiseapi",
], function (_, tinybatch, api) {
	var ctx;
	function CustomError(message, subject) {
		this.constructor.prototype.__proto__ = Error.prototype;
		this.name = this.constructor.name;
		this.message = message;
		this.subject = subject;
	}

	let batchapi = async (tag, t, batch) => {
		if (_.get(ctx, "global._t_app.locals._t_clientbatch")) {
			const _bctx = _.cloneDeep(bctx);
			_bctx.t = t;
			await tinybatch.batch(_bctx, batch);
			return _bctx.result;
		}
		if (typeof window == "undefined")
			return await api("batch.run", t, { tag, batch });
		return await api(`batch.run?${tag.replaceAll(".", "-")}`, t, { tag, batch });
	};

	const bctx = {
		actions: {
			api: {
				match: ".*",
				action: async (action, params, _bctx) => {
					function p() {
						return new Promise((resolve) => {
							api(action, _bctx.t, params, (err, res) => {
								if (err)
									throw new CustomError(err, "Custom error");
								resolve(res);
							});
						});
					}
					return await p();
				},
			},
		},
	};

	batchapi.setCtx = function (ctx_) {
		ctx = ctx_;
	};

	return batchapi;
});
