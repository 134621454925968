(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("$"));
	else if(typeof define === 'function' && define.amd)
		define(["$"], factory);
	else if(typeof exports === 'object')
		exports["garner"] = factory(require("$"));
	else
		root["garner"] = factory(root["$"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__2319__) => {
return 