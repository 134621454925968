define(["jquery"], function ($) {
	"use strict";

	class PushService {
		constructor(user) {
			this.PUSH = "/push/";
			this._iduser = user && user._id;
		}

		initPushMessages() {
			if (!this._iduser) return;
			return this.doPushubscription();
		}

		async registerSubscription(subscription) {
			return $.ajax({
				method: "POST",
				url: this.PUSH + "register",
				headers: {
					"Content-type": "application/json",
				},
				data: JSON.stringify({
					subscription,
					_iduser: this._iduser,
				}),
			});
		}

		async doPushubscription() {
			if (!(
				navigator &&
                navigator.serviceWorker &&
                navigator.serviceWorker.register
			)) return Promise.resolve();

			await navigator.serviceWorker.register("/service-worker.js", {
				scope: "/",
			});
			navigator.serviceWorker.ready.then(async (regs) => {
				await regs.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: "BHaE8nYo_S-6771vrTfyf9iLoALO4hU-NQGmU8hIMIMPTljXM_--qgn1r-RosRlN_t3GGMXlitQRDKBeajQTX_g",
				});
				const subscription = await regs.pushManager.getSubscription();
				await this.registerSubscription(subscription);
			});
		}

		urlBase64ToUint8Array(base64String) {
			const padding = "=".repeat((4 - base64String.length % 4) % 4);
			const base64 = (base64String + padding)
				.replace(/-/g, "+")
				.replace(/_/g, "/");
			const rawData = window.atob(base64);
			return Uint8Array.from(Array.from(rawData).map(char => char.charCodeAt(0)));
		}
	}

	return PushService;
});
