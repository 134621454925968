define([
	"../../../regionConfig.js",
	"views/layout/layout",
	"views/layout/empty",
	"safe",
	"dust.core",
	"tinybone/base",
	"lodash",
	"tson",
	"tinybone/backadapter",
	"jquery",
	"moment",
	"big",
	"node-gettext",
	"clientbatch",
	"jquery.blockUI",
	"dust-helpers",
	"../style/style.scss",
	"foundation.core",
], function (cfg, Layout, EmptyLayout, safe, dust, tb, _, tson, api, $, moment, Decimal, GetText, batchapi) {
	// Make sure dust.helpers is an object before adding a new helper.
	if (!dust.helpers)
		dust.helpers = {};

	dust.helpers.gettext = function (chunk, context, bodies, params) {
		const gt = context.get("_t_gettext") || ((typeof window !== "undefined") && window._t_gettext);
		if (!gt)
			console.warn("Gettext is not initialized");
		var translatedString = gt ? gt.gettext(params.key) : params.key;
		return chunk.write(translatedString);
	};

	Date.nowUTC = function () {
		return Date.now() - (new Date().getTimezoneOffset() * 60000);
	};

	/**
	 * @param params
	 * @param {Date} params.date
	 * @param {String} params.format
	 * @returns {*}
	 */
	dust.helpers.formatdate = function (chunk, context, bodies, params) {
		var m = moment(params.date),
			defFormat = "MM/DD/YYYY HH:mm";
		return chunk.write(m.format(params.format || defFormat));
	};

	dust.helpers.formatdateUTC = function (chunk, context, bodies, params) {
		var m = moment.utc(params.date),
			defFormat = "MM/DD/YYYY HH:mm";
		return chunk.write(m.format(params.format || defFormat));
	};

	dust.helpers.formattime = function (chunk, context, bodies, params) {
		var d = moment().startOf("day").minutes(params.time),
			str = "";
		var h = d.hours();
		if (h)
			str += h + " HOUR" + (h > 1 ? "S" : "");

		var m = d.minutes();
		if (m)
			str += (h ? " E " : "") + m + " MIN";

		return chunk.write(str);
	};

	dust.helpers.toFixed = function (chunk, context, bodies, params) {
		var num = Number(params.num);
		var accuracy = Number(params.accuracy);
		return num.toFixed(accuracy);
	};

	dust.helpers.uniq = function (chunk, context, bodies, params) {
		context.current()._t_uniq = _.uniqueId();
	};

	/**
	 * @param {Object} params
	 * @param {String} params.value
	 * @param {String} params.lang
	 */
	dust.helpers.i18n_unit = function (chunk, context, bodies, params) {
		const gt = context.get("_t_gettext") || ((typeof window !== "undefined") && window._t_gettext);
		let unit = getUnit({ value: { type: params.value } }, gt.locale);
		return chunk.write(unit[1]);
	};

	/**
	 * @param {Object} params
	 * @param {{ type: String, _f_val: Number }} params.value
	 * @param {String} params.lang
	 */
	dust.helpers.i18n_unit_value = function (chunk, context, bodies, params) {
		const gt = context.get("_t_gettext") || ((typeof window !== "undefined") && window._t_gettext);
		let unit = getUnit(params, gt.locale);
		let tr = null;
		let unitRatios = _(unit).keys().map(parseFloat).sort().value();
		let symbol = "";
		let val = params.value && params.value._f_val;
		if (val === undefined) {
			val = 1;
			console.warn("i18n_unit_value, invalid value supplied");
		}

		if (val === 0)
			tr = { k: unit[1] };
		else {
			if (val < 0) {
				symbol = "-";
				val = val * -1;
			}
			unitRatios.forEach((u) => {
				if (!(val >= u || !tr)) return;
				tr = { u, k: unit[u] };
			});
			if (!tr) throw new Error("Translatable unit not found!");
			val = new Decimal(val).div(tr.u).valueOf();
		}
		// controversial decision 22.04.22
		val = Math.round(val * 1000) / 1000;
		return chunk.write(`${symbol}${val} ${tr.k}`);
	};

	function getUnit(params, locale) {
		let lang = locale || "ru";
		const { localizationUnits } = cfg;
		let local = localizationUnits[lang];
		if (!local) {
			local = localizationUnits["ru"];
			console.warn("Invalid lang " + lang);
		}

		let unit = null;
		if (params.value && params.value.type)
			unit = local[params.value.type];
		if (!unit) {
			unit = local.pc;
			console.warn("Invalid unit " + (params.value && params.value.type));
		}

		return unit;
	}

	dust.helpers.first_letter = function (chunk, context, bodies, params) {
		if (params.value && params.value.length)
			return chunk.write(params.value[0].toUpperCase());
		return "@";
	};

	dust.helpers.i18n_distance = function (chunk, context, bodies, params) {
		let value,
			type;

		if (params.value > 1000) {
			value = Math.round(params.value / 100) / 10;
			type = "km";
		} else if (params.value >= 0) {
			value = (params.value * 1.0).toFixed(0);
			type = "m";
		}
		if (value != null)
			return chunk.write(`${value} ${type}`);
		else
			return chunk.write("&infin;");
	};

	dust.helpers.i18n_price = function (chunk, context, bodies, params) {
		if (params.value > 0) {
			const value = parseInt(params.value * 100) / 100;
			return chunk.write(`${value} ₽`);
		} else return chunk.write("&infin; ₽");
	};

	dust.helpers.i18n_money = function (chunk, context, bodies, params) {
		if (!params.data || !params.data._f_val || !params.data.iso) return chunk.write("0");
		const { _f_val, iso } = params.data;
		if (_f_val < 0) return chunk.write("&infin;");
		const result = { _f_val, iso };
		const gt = context.get("_t_gettext") || ((typeof window !== "undefined") && window._t_gettext);
		if (!gt)
			console.warn("Gettext is not initialized");
		const currency = context.get("currentUser").currency;
		if (currency && iso !== currency) {
			result.iso = currency;
			let exchangeRates = context.get("rates");
			if (typeof exchangeRates === "string")
				exchangeRates = JSON.parse(`{"data": ${exchangeRates.substring(2, exchangeRates.length)}}`).data;
			const exchangeRate = exchangeRates.find(e => e.from == iso && e.to == currency);
			const reversedExchangeRate = exchangeRates.find(e => e.from == currency && e.to == iso);
			const rate = exchangeRate
				? exchangeRate.value
				: reversedExchangeRate
					? 1 / reversedExchangeRate.value
					: 0;
			result._f_val = _f_val * rate;
		}
		let formatter = new Intl.NumberFormat(gt.locale, {
			style: "currency",
			currency: result.iso,
			currencyDisplay: "narrowSymbol",
			signDisplay: "never",
		});
		return chunk.write(formatter.format(result._f_val));
	};

	let gettexts = {};

	function getGetText(locale) {
		let gt = gettexts[locale];
		if (!gt) {
			gt = new GetText();
			var messages = require(`../locales/${locale}/messages.pot`);
			gt.addTranslations(locale, "messages", messages);
			gt.setLocale(locale);
			gettexts[locale] = gt;
			// client only set global gettext
			if (typeof window != "undefined")
				window._t_gettext = gt;
		}
		return gt;
	}

	return tb.Application.extend({
		getTplCtx: function (cb) {
			tb.Application.prototype.getTplCtx.call(this, safe.sure(cb, (ctx) => {
				ctx = ctx.push(_.extend({}, this.locals || {}));
				batchapi.setCtx(ctx);
				safe.back(cb, null, ctx);
			}));
		},
		getLocalPath: function () {
			return "/web"; // WODO module.uri.replace("app.js", "");
		},
		getView: function (opts) {
			if (opts && opts.empty)
				return new EmptyLayout({ app: this });
			return new Layout({ app: this });
		},
		errHandler: function (err) {
			if (err)
				console.error(err);
		},
		clientError: function (error) {
			var self = this;
			if (!error || !(error instanceof Error)) return;
			if (error === "Error" || error === "error") return;
			if (error.subject === "Error" || error.subject === "error") error.subject = "Ошибка сети";
			require(["views/common/error"], function (Error) {
				var err = new Error({
					app: self,
					data: {
						subject: error.subject,
						message: error.message,
					},
				});
				err.render(safe.sure(self.errHandler, function (text) {
					var $errcase = $("#errMessages");
					let $text = $(text);
					$errcase.html($text);
					err.bindDom($text);
				}));
			}, self.errHandler);
			// in addiotion to make it visible, also log it
			this.errHandler(error);
		},
		blockLayer: function ($el) {
			let self = this;
			if (!$el)
				$el = $("body");

			$el.block({
				message: "<img style='width:32px; height:32px;' src='" + self.prefix + "/img/loader.gif'>",
				css: {
					textAlign:	"center",
					cursor:	"wait",
					border: "none",
					background: "transparent",
				},
				themedCSS: {
					width:	"30%",
					top:	"40%",
					left:	"35%",
				},
				overlayCSS: {
					backgroundColor:	"#000",
					opacity:	0.1,
					cursor:	"wait",
				},
			});
			let $top = Math.round(window.innerHeight / 2) + "px";
			$(".blockUI.blockMsg.blockElement").css("top", $top);
			$("body").css("overflow-y", "hidden");
		},
		unblockLayer: function ($el) {
			if (!$el)
				$el = $("body");

			$el.unblock();
			$("body").css("overflow-y", "auto");
		},
		isServer: function () {
			return (typeof window === "undefined");
		},
		getToken: function () {
			return $.cookie("token");
		},
		clearToken: function () {
			$.removeCookie("token", { path: "/" });
			$.removeCookie("_t_refresh", { path: "/" });
			$.removeCookie("geoloc", { path: "/" });
		},
		initRoutes: function (cb) {
			var self = this;
			var router = self.router;
			require([
				"routes/main",
			], function (main) {
				// some standard locals grabber
				router.use(function (req, res, next) {
					res.locals.token = req.cookies.token || "public";
					res.locals._t_req = _.pick(req, ["path", "query", "baseUrl"]);
					res.locals.rates = req.cookies.rates || false;
					if (req.cookies.geoloc) {
						let geoloc = JSON.parse(req.cookies.geoloc);
						res.locals.geoloc = geoloc;
					}
					next();
				});

				function checkAuth(req, res, next) {
					if (res.locals.token == "public") {
						let token = Math.random().toString(36).slice(-14);
						res.cookie("token", token, { path: "/" });
						res.locals.token = token;
					}
					safe.run((cb) => {
						api("users.ensureUser", res.locals.token, {
							name: 1,
							_id: 1,
							config: 1,
						}, safe.sure(cb, (u) => {
							if (!u.language) {
								var languageFromHeader = _.get(req.headers, "accept-language", "ru-ru");
								var re = languageFromHeader.match(/\w\w-\w\w/i);
								u.language = re ? (re[0][0] + re[0][1]) : "ru";
								api("users.setLanguage", res.locals.token, {
									_id: u._id,
									language: u.language,
								}, function () {});
							}
							if (!u.currency) {
								let currency = "RUB"; // DEFAULT CURRENCY
								if (res.locals.geoloc)
									currency = res.locals.geoloc.country === "RU" ? "RUB" : "AMD";
								else if (u.language === "ru")
									currency = "RUB";
								u.currency = currency;
								api("users.setCurrency", res.locals.token, {
									_id: u._id,
									currency: u.currency,
								}, function () {});
							}
							res.locals._t_gettext = getGetText(u.language);
							res.locals.currentUser = u;
							if (
								!res.locals.rates ||
								moment().startOf("day").isAfter(moment(res.locals.rates[0].date, "YYYY-MM-DD"))
							) {
								const currencies = _.uniq(_.map(cfg.regions, "currency"));
								api("coreapi.getExchangeRates", res.locals.token, currencies,
									function (err, result) {
										res.locals.rates = result;
										res.cookie("rates", result, { path: "/" });
										api("users.extendTokenTTL", res.locals.token, {}, cb);
									},
								);
							} else
								api("users.extendTokenTTL", res.locals.token, {}, cb);
						}));
					}, next);
				}

				function notGuest(req, res, next) {
					if (res.locals.currentUser && res.locals.currentUser.role == "guest")
						main.login(req, res, next);
					else next();
				}

				function isPublic(req, res, next) {
					res.locals.isPublic = 1;
					next();
				}

				router.get("/", isPublic, checkAuth, (req, res, next) => {
					require(["routes/index"], (route) => {
						route(req, res, next);
					}, next);
				});
				router.get("/login", isPublic, checkAuth, function (req, res, next) {
					if (res.locals.currentUser && res.locals.currentUser.role != "guest") {
						res.redirect("/web");
						return;
					}
					main.login(req, res, next);
				});
				router.get("/points", checkAuth, notGuest, function (req, res, next) {
					require(["routes/points"], (route) => {
						route.points(req, res, next);
					}, next);
				});

				router.get("/register", isPublic, checkAuth, function (req, res, next) {
					if (res.locals.currentUser && res.locals.currentUser.role != "guest") {
						res.redirect("/web");
						return;
					}
					main.check(req, res, next);
				});
				router.get("/forgot", isPublic, checkAuth, function (req, res, next) {
					if (res.locals.currentUser && res.locals.currentUser.role != "guest") {
						res.redirect("/web");
						return;
					}
					main.forgot(req, res, next);
				});
				router.get("/reset/", checkAuth, main.invitation);
				router.get("/confirm/", checkAuth, main.invitationUser);

				router.get("/product-details/:_idprimary", checkAuth, (req, res, next) => {
					require(["routes/pdp"], async (route) => {
						try {
							await route.product_details(req, res);
						} catch (e) {
							next(e);
						}
					}, next);
				});
				router.get("/shopping-cart/:_idlot", checkAuth, (req, res, next) => {
					require(["routes/pdp"], async (route) => {
						try {
							await route.shopping_cart(req, res);
						} catch (e) {
							next(e);
						}
					}, next);
				});

				router.get("/products", checkAuth, notGuest, function (req, res, next) {
					require(["routes/product"], (route) => {
						route.products(req, res, next);
					}, next);
				});
				router.get("/edit-product/:_idproduct", checkAuth, notGuest, function (req, res, next) {
					require(["routes/product"], (route) => {
						route.editProduct(req, res, next);
					}, next);
				});
				router.get("/edit-product-quantity/:_idproduct", checkAuth, notGuest, function (req, res, next) {
					require(["routes/product"], (route) => {
						route.editProductQuantity(req, res, next);
					}, next);
				});
				router.get("/lots", checkAuth, notGuest, function (req, res, next) {
					require(["routes/lot"], (route) => {
						route.lots(req, res, next);
					}, next);
				});
				router.get("/favorites", checkAuth, notGuest, function (req, res, next) {
					if (res.locals.currentUser && res.locals.currentUser.role == "guest") {
						res.redirect("/web/login");
						return;
					}
					require(["routes/product"], (route) => {
						route.favorites(req, res, next);
					}, next);
				});
				router.get("/edit-lot/:_idlot", checkAuth, notGuest, function (req, res, next) {
					require(["routes/lot"], (route) => {
						route.editLot(req, res, next);
					}, next);
				});

				router.get("/edit-profile", checkAuth, notGuest, function (req, res, next) {
					require(["routes/profile"], (route) => {
						route.editProfile(req, res, next);
					}, next);
				});

				router.get("/deal/:id", checkAuth, notGuest, function (req, res, next) {
					require(["routes/deal"], (route) => {
						route.deal(req, res, next);
					}, next);
				});
				router.get("/my-deals", checkAuth, notGuest, function (req, res, next) {
					require(["routes/deal"], async (route) => {
						try {
							await route.myDeals(req, res);
						} catch (e) {
							next(e);
						}
					}, next);
				});
				router.get("/management_deals", checkAuth, notGuest, function (req, res, next) {
					require(["routes/deal"], (route) => {
						route.conflicts(req, res, next);
					}, next);
				});
				router.get("/profile/:_iduser", checkAuth, notGuest, function (req, res, next) {
					require(["routes/profile"], (route) => {
						route.sellerProfile(req, res, next);
					}, next);
				});
				router.get("/users-managment", checkAuth, notGuest, function (req, res, next) {
					require(["routes/users-managment"], (route) => {
						route.getUsersList(req, res, next);
					}, next);
				});

				// error handler after that
				router.use(function (err, req, res, cb) {
					if (err.subject)
						if (err.subject == "Unauthorized")
							main.login(req, res, cb);
						else if (err.subject == "Access forbidden")
							require(["views/common/403"], safe.trap(cb, function (view) {
								res.status(403);
								res.renderX({
									view: view,
									route: req.route.path,
									data: { title: "Access forbidden" },
								});
							}), cb);
						else if (err.subject == "Not Found")
							require(["views/common/404"], safe.trap(cb, function (view) {
								res.status(404);
								res.renderX({
									view: view,
									route: req.route.path,
									data: { title: "Page Not Found" },
								});
							}), cb);

						else
							cb(err);

					else
						cb(err);
				});
				router.use(function (err, req, res, cb) {
					self.errHandler(err);
					cb(err);
				});
				cb();
			}, cb);
		},
		getDefaultRoute: function (cb) {
			cb(null, this.prefix);
		},
		getPrefix: function () {
			return this.prefix;
		},
		setCtx: function (ctx) {
			api.setCtx(ctx);
		},
		init: function (wire, cb) {
			var self = this;
			wire = tson.decode(wire);

			if (!cb)
				cb = this.clientHardError;

			$.blockUI.defaults.message = "<h4>Loading ...</h4>";
			$.blockUI.defaults.overlayCSS = {
				backgroundColor: "#FFF",
				opacity: 0,
				cursor: "wait",
			};

			this.prefix = wire.prefix;
			this.wrapErrors = wire.wrapErrors;
			this._t_cfgData = wire._t_cfgData;
			this.router = new tb.Router({
				prefix: "/web", // module.uri.replace("/app/app.js", "")
			});
			this.router.on("start", function (route) {
				$.blockUI();
				self._pageLoad = { start: new Date(), route: route.route };
			});

			if (!this.mainView)
				this.mainView = new Layout({ app: this });

			var mainView = this.mainView;

			// Inject GetText for view.get()
			const baseViewGet = tb.View.prototype.get;
			getGetText(wire._t_locale);
			tb.View.prototype.get = function (k, def) {
				if (k == "_t_gettext")
					return window._t_gettext;
				return baseViewGet.call(this, k, def);
			};

			this.router.use(function (req, res, next) {
				res.status = function () {};
				res.redirect = function (path, cb) {
					var req = this.req;
					cb = cb || function (err) {
						req._t_done(err);
					};
					self.router.navigateTo(path, { replace: true }, cb);
				};
				res.renderX = function (route, cb) {
					var req = this.req;
					cb = cb || function (err) {
						req._t_done(err);
					};
					self.clientRender(this, route, cb);
				};
				next();
			});
			// init routes
			this.initRoutes(safe.sure(cb, function () {
				// register last chance error handler
				self.router.use(function (err, res, req, next) {
					self.clientHardError(err);
					cb(null);
				});
				// make app alive
				// this hack bellow is to please webpack, in fact we conditionally dynammically include
				// all "pages", root view of each route
				safe.parallel([
					(cb) => {
						const view = "views/index/start";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/index/start"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/index/search_item_product_details";
						if (wire.views[0].name != view) return cb(null, view);
						require([
							"views/index/search_item_product_details",
						], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/deal/deals";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/deal/deals"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/lots/lots";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/lots/lots"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/lots/lots_edit";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/lots/lots_edit"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/products/products";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/products/products"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/lots/lots";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/lots/lots"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/points/point";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/points/point"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/index/favorites";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/index/favorites"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/profiles/profile_edit";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/profiles/profile_edit"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/conflicts/conflicts";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/conflicts/conflicts"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/users-managment/users-managment";
						if (wire.views[0].name != view) return cb(null, view);
						require([
							"views/users-managment/users-managment",
						], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/common/404";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/common/404"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/common/error";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/common/error"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/profiles/seller_profile";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/profiles/seller_profile"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/signup/check_in";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/signup/check_in"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/invitation/registration";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/invitation/registration"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/invitation/confirm_email";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/invitation/confirm_email"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/signup/forgot";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/signup/forgot"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/signup/signin";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/signup/signin"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/deal/deal_edit";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/deal/deal_edit"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/products/products_edit";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/products/products_edit"], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/products/product_edit_quantity";
						if (wire.views[0].name != view) return cb(null, view);
						require([
							"views/products/product_edit_quantity",
						], () => cb(null, view), cb);
					},
					(cb) => {
						const view = "views/index/shopping_cart";
						if (wire.views[0].name != view) return cb(null, view);
						require(["views/index/shopping_cart"], () => cb(null, view), cb);
					},
				], safe.sure(cb, (res) => {
					if (!_.find(res, v => v == wire.views[0].name))
						return cb(new Error(`No view preloaded with name ${wire.views[0].name}. Please modify app.js`));
					mainView.bindWire(wire, null, null, safe.sure(cb, function () {
						$("body").attr("data-id", (new Date()).valueOf());
					}));
				}));
			}));

			setInterval(function () {
				api("users.setOnlineStatus", $.cookie("token"), {
					online: true,
				}, function (err, u) {});
			}, 300000);
		},
		clientHardError: function (err) {
			var self = this;
			if (err)
				if (self.wrapErrors)
					$("body").html("<div class=\"container signin-container\"><div class=\"row\"><div class=\"col-md-4 col-md-offset-4 cols-xs-12\" id=\"signinf\"><form role=\"form\"> <div class=\"err-case active\">Oops... Something happens...<br>Please navigate to <a href=\"" + self.prefix + "\">home</a></div></form></div></div></div>");

				else
					$("body").html("<div class='hard-client-error'><h1>Oops, looks like somethething went wrong.</h1><br>" +
							"We've get notified and looking on it. <b>Meanwhile try to refresh page or go back</b>.<br><br>" +
							"<pre>" + err + "\n" + err.stack + "</pre></div>");
		},
		clientRender: function (res, route, cb) {
			var self = this;

			// tickmark for data ready time
			this._pageLoad.data = new Date();

			// create new view, bind data to it
			var mainView = this.mainView;
			var view = new route.view({ app: self });
			view.data = route.data;
			view.locals = res.locals;

			// render
			view.render(safe.sure(cb, function (text) {
				// render dom nodes and bind view
				var exViews = _.filter(mainView.views, function (v) {
					return v.cid != view.cid;
				});
				var oldView = exViews.length == 1 ? exViews[0] : undefined;
				var $dom = $(text);
				mainView.$el.append($dom);
				view.bindDom($dom, oldView);

				// remove all root views except new one and hard error (if any)
				$(".hard-client-error").remove();
				_.each(exViews, function (v) {
					v.remove();
				});

				mainView.attachSubView(view);


				// view is actually ready, finalizing
				document.title = route.data.title;
				$.unblockUI();
				$("body").attr("data-id", (new Date()).valueOf());
				if (window.Tinelic) {
					// do analytics
					self._pageLoad.dom = new Date();
					var m = {
						_i_nt: self._pageLoad.data.valueOf() - self._pageLoad.start.valueOf(),
						_i_dt: self._pageLoad.dom.valueOf() - self._pageLoad.data.valueOf(),
						_i_lt: 0,
						r: self._pageLoad.route,
					};
					window.Tinelic.pageLoad(m);
				}
				window.trackPageView(window.location.pathname);
				cb(null);
			}));
		},
		renderView: function (View, data, cb) {
			let self = this;
			let view = new View({
				app: self,
				data,
			});
			view.locals._t_gettext = window._t_gettext;
			view.render(safe.sure_result(cb, function (text) {
				let $text = $(text);
				return {
					view,
					$el: $text,
					bindDom: function () {
						view.bindDom($text);
					},
				};
			}));
		},
	});
});
