define([
	"tinybone/base",
	"dustc!views/common/notify_count.dust",
], function (tb) {
	var view = tb.View;
	var View = view.extend({
		id: "views/common/notify_count",
	});
	View.id = "views/common/notify_count";
	return View;
});
