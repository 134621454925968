
define([
	"tinybone/base",
	"dustc!views/common/top_profile_menu.dust",
], function (tb) {
	var view = tb.View;
	var View = view.extend({
		id: "views/common/top_profile_menu",
		postRender: function () {},
		events: {},
	});
	View.id = "views/common/top_profile_menu";
	return View;
});
