define([
	"tinybone/base",
	"tinybone/backadapter",
	"jquery",
	"safe",
	"views/common/pushService",
	"dustc!views/layout/layout.dust",
	"dustc!views/base.dust",
], function (tb, api, $, safe, PushService) {
	var view = tb.View;
	var View = view.extend({
		rctx: "/teacher",
		id: "views/layout/layout",
		postRender: function () {
			let pushService = new PushService(this.get("currentUser"));
			pushService.initPushMessages();
		},
		events: {
			"click a": function (e) {
				if (e.shiftKey || e.ctrlKey || $(e.currentTarget).hasClass("target-blank"))
					return;

				e.preventDefault();
				var href = $(e.currentTarget).attr("href");
				if (href && href.length > 1)
					this.app.router.navigateTo($(e.currentTarget).attr("href"), this.app.errHandler);
			},
		},
	});
	View.id = "views/layout/layout";
	return View;
});
