define(["jquery", "foundation.reveal"], function ($) {
	function CustomDialog(opts) {
		let {
			title,
			text,
			size,
			buttonConfirmText,
			buttonCancelText,
			confirmButton,
			cancelButton,
		} = opts;
		this.title = title;
		this.text = text;
		this.size = size || "";
		this._events = {
			validate: null,
			confirm: function () {},
			reject: function () {},
		};
		this.buttonConfirmText = buttonConfirmText || "Подтвердить";
		this.buttonCancelText = buttonCancelText || "Отменить";
		if (confirmButton !== undefined)
			this.confirmButton = confirmButton;
		else
			this.confirmButton = true;

		if (cancelButton !== undefined)
			this.cancelButton = cancelButton;
		else
			this.cancelButton = true;
	}

	CustomDialog.prototype = {
		validate,
		confirm,
		reject,
		show,
		alert,
		frame,
		getModal,
		_getModalElement,
	};

	function show() {
		let $modal = this._getModalElement();
		$modal.find(".modal-body").html(this.text);
		$modal.foundation();
		$modal.foundation("open");
		$modal.find(".close-button").hide();
		$modal.on("click", ".modal-confirm", () => {
			if (this._events.validate != null && !this._events.validate())
				return false;

			this._events.confirm();
		});
		$modal.on("click", ".modal-reject", this._events.reject);
		this.$modal = $modal;
	}

	function alert() {
		this.show();
		this.getModal().find(".close-button").hide();
		this.getModal().find(".modal-confirm").hide();
	}

	function frame() {
		this.show();
		this.getModal().find(".close-button").show();
		this.getModal().find(".modal-footer").hide();
	}

	function getModal() {
		if (!this.$modal) throw new Error("Modal is not opened.");
		return this.$modal;
	}

	function validate(fn) {
		this._events.validate = fn;
	}

	function confirm(fn) {
		this._events.confirm = fn;
	}

	function reject(fn) {
		this._events.reject = fn;
	}

	function _getModalElement() {
		let confirmButton = "";
		let cancelButton = "";
		if (this.confirmButton)
			confirmButton =
			`<div class="button warning modal-confirm" data-close>
				${this.buttonConfirmText}
			</div>`;
		if (this.cancelButton)
			cancelButton =
			`<div class="button secondary modal-reject" data-close>
				${this.buttonCancelText}
			</div>`;
		return $(`
		<div class="reveal alert ${this.size}" id="alert-modal" data-reveal data-v-offset="132">
			<button class="close-button" data-close aria-label="Close modal" type="button">
				<span aria-hidden="true">&times;</span>
			</button>

			<h5>${this.title}</h5>
			<p class="modal-body"></p>
			<div class="modal-footer button-group align-right">
				${confirmButton}
				${cancelButton}
			</div>
		</div>
		`);
	}

	return CustomDialog;
});
