// eslint-disable-next-line no-unused-vars
import React from "react";

export default function Avatar(props) {
	const avatar = props.get("currentUser._id_avatar");
	if (avatar)
		return (
			<div
				className="profile-menu-avatar"
				style={{ backgroundImage: `url('/file/img/${avatar}?h=180')` }}
			/>
		);
	else {
		const name = props.get("currentUser.name").charAt(0);
		return (
			<div className="sphere bg-green-1"><p>{name}</p></div>
		);
	}
}
