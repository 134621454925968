define(["lodash", "tinybone/backadapter"], function (_, api) {
	return function (...args) {
		if (args.length > 0 && _.isFunction(args[args.length - 1]))
			api.apply(this, args);
		else
			// Promise.promisify reprap, we just wrapping ourselves, newrelic instrumentation working ^^^^
			return new Promise((resolve, reject) => {
				args.push(function (err, result) {
					if (err)
						return reject(err);

					resolve(result);
				});
				try {
					api.apply(this, args);
				} catch (err) {
					reject(err);
				}
			});
	};
});
